import React, { useState, useEffect, useRef, useCallback } from 'react';
import Globe from 'react-globe.gl';
import airportsData from './airports.json';
import RecordRTC from 'recordrtc';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import {
  AppBar, Toolbar, Typography, Button, TextField,
  Autocomplete, Box, CssBaseline, ThemeProvider, createTheme, Paper
} from '@mui/material';
import { Flight } from '@mui/icons-material';
import About from './About';
import { getDistance } from 'geolib';

const darkTheme = createTheme({
  
  palette: {
    mode: 'dark',
  },
});

// Initialize ffmpeg outside the component to ensure it's only loaded once
const ffmpeg = new FFmpeg();
let ffmpegLoaded = false;

function App() {
  const [airports, setAirports] = useState([]);
  const [selectedAirports, setSelectedAirports] = useState([null, null]);
  const [arcs, setArcs] = useState([]);
  const [labels, setLabels] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const globeEl = useRef();
  const recorder = useRef(null);
  const [arcData, setArcData] = useState([]);
  const [arcProgress, setArcProgress] = useState(0);
  const animationRef = useRef();
  const [showAbout, setShowAbout] = useState(false);
  const [processingTime, setProcessingTime] = useState(0);
  const processingTimerRef = useRef(null);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const countdownTimerRef = useRef(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const generationTimeoutRef = useRef(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const elapsedTimeRef = useRef(null);

  const animateArc = useCallback(() => {
    setArcProgress(progress => {
      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateArc);
        return progress + 0.005; // Adjust this value to change animation speed
      }
      return 1;
    });
  }, []);

  useEffect(() => {
    setAirports(airportsData);
  }, []);

  useEffect(() => {
    if (selectedAirports[0] && selectedAirports[1]) {
      const start = selectedAirports[0];
      const end = selectedAirports[1];

      const arc = {
        startLat: start.lat,
        startLng: start.lng,
        endLat: end.lat,
        endLng: end.lng,
        color: 'red'
      };
      setArcData([arc]);
      setArcProgress(0);
      animationRef.current = requestAnimationFrame(animateArc);

      // Set labels for both airports
      setLabels([
        { lat: start.lat, lng: start.lng, text: start.name },
        { lat: end.lat, lng: end.lng, text: end.name }
      ]);

      globeEl.current.pointOfView({
        lat: start.lat,
        lng: start.lng,
        altitude: 2.5,
      }, 0);
    } else {
      setArcData([]);
      setLabels([]);
      setArcProgress(0);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      setArcs([]);
      setLabels([]);
    }
  }, [selectedAirports, animateArc]);

  const handleAirportChange = (newValue, index) => {
    const newSelectedAirports = [...selectedAirports];
    newSelectedAirports[index] = newValue;
    setSelectedAirports(newSelectedAirports);
  };

  const estimateVideoTime = useCallback(() => {
    if (selectedAirports[0] && selectedAirports[1]) {
      const start = selectedAirports[0];
      const end = selectedAirports[1];
      const distance = getDistance(
        { latitude: start.lat, longitude: start.lng },
        { latitude: end.lat, longitude: end.lng }
      );
      // Estimate 1 second of video per 100km of distance, plus 30 seconds for processing
      const estimatedSeconds = Math.round(distance / 100000) + 30;
      setEstimatedTime(estimatedSeconds);
    } else {
      setEstimatedTime(0);
    }
  }, [selectedAirports]);

  useEffect(() => {
    estimateVideoTime();
  }, [selectedAirports, estimateVideoTime]);

  const startCountdownTimer = useCallback(() => {
    if (estimatedTime > 0) {
      setRemainingTime(estimatedTime);
      countdownTimerRef.current = setInterval(() => {
        setRemainingTime(prevTime => {
          if (prevTime <= 1) {
            clearInterval(countdownTimerRef.current);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
  }, [estimatedTime]);

  const cancelGeneration = useCallback(() => {
    if (recorder.current) {
      recorder.current.stopRecording(() => {
        //console.log('Recording cancelled');
      });
    }
    clearTimeout(generationTimeoutRef.current);
    clearInterval(processingTimerRef.current);
    clearInterval(countdownTimerRef.current);
    clearInterval(elapsedTimeRef.current);
    setIsGenerating(false);
    setIsRecording(false);
    setProcessingTime(0);
    setRemainingTime(0);
    setElapsedTime(0);
  }, []);

  const loadFFmpeg = async () => {
    if (!ffmpegLoaded) {
      try {
        await ffmpeg.load();
        ffmpegLoaded = true;
        console.log('FFmpeg loaded successfully');
      } catch (error) {
        console.error('Error loading FFmpeg:', error);
      }
    }
  };

  useEffect(() => {
    loadFFmpeg();
  }, []);

  const execWithTimeout = async (ffmpeg, args, timeout) => {
    return Promise.race([
      ffmpeg.exec(args),
      new Promise((_, reject) => 
        setTimeout(() => reject(new Error('FFmpeg step timed out')), timeout)
      )
    ]);
  };

  const processVideo = async (blob) => {
    console.log('Starting video processing...');

    try {
      if (!ffmpegLoaded) {
        console.log('Loading FFmpeg...');
        await loadFFmpeg();
        console.log('FFmpeg loaded successfully');
      }

      console.log('Writing input file...');
      await ffmpeg.writeFile('input.webm', await fetchFile(blob));
      console.log('Input file written successfully');

      console.log('Executing FFmpeg command...');
      
      // Single FFmpeg step
      const args = [
        '-i', 'input.webm',
        '-c:v', 'libx264',
        '-preset', 'ultrafast',
        '-crf', '23',
        '-vf', 'scale=1920:1080:force_original_aspect_ratio=decrease,pad=1920:1080:(ow-iw)/2:(oh-ih)/2',
        'output.mp4'
      ];

      console.log('FFmpeg command arguments:', args.join(' '));
      console.log('Executing FFmpeg step...');
      console.time('FFmpeg step');
      try {
        await execWithTimeout(ffmpeg, args, 180000); // 3-minute timeout
      } catch (error) {
        console.error('Error in FFmpeg step:', error);
        throw error;
      }
      console.timeEnd('FFmpeg step');
      console.log('FFmpeg step completed');

      console.log('FFmpeg processing completed');

      console.log('Reading output file...');
      const data = await ffmpeg.readFile('output.mp4');
      console.log('Output file read successfully, data length:', data.length);

      const mp4Blob = new Blob([data.buffer], { type: 'video/mp4' });
      console.log('MP4 Blob created, size:', mp4Blob.size, 'bytes');
      const mp4Url = URL.createObjectURL(mp4Blob);
      console.log('MP4 URL created:', mp4Url);

      return mp4Url;
    } catch (error) {
      console.error('Error during video processing:', error);
      throw error;
    }
  };

  const animateFlightPath = async () => {
    setIsGenerating(true);
    setElapsedTime(0);
    elapsedTimeRef.current = setInterval(() => {
      setElapsedTime(prev => prev + 1);
    }, 1000);

    // Increase the timeout duration significantly
    const timeoutDuration = 600000; // 10 minutes
    generationTimeoutRef.current = setTimeout(() => {
      console.error('Video generation timed out');
      cancelGeneration();
    }, timeoutDuration);

    if (!selectedAirports[0] || !selectedAirports[1]) return;

    const start = selectedAirports[0];
    const end = selectedAirports[1];
    const numFrames = 300;
    const frameDuration = 1000 / 60; // 60 FPS

    const canvas = globeEl.current.renderer().domElement;
    const stream = canvas.captureStream(30); // Capture at 30 fps

    let mimeType = 'video/webm;codecs=vp8'; // Use VP8 for compatibility
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      mimeType = 'video/webm'; // Fallback if VP8 is not supported
    }

    recorder.current = new RecordRTC(stream, {
      type: 'video',
      mimeType: mimeType,
      frameInterval: 1,
      frameRate: 60,
      quality: 100, // Highest quality
      videoBitsPerSecond: 8000000, // 8 Mbps
    });

    recorder.current.startRecording();
    setIsRecording(true);
    setProcessingTime(0);
    processingTimerRef.current = setInterval(() => {
      setProcessingTime(prevTime => prevTime + 1);
    }, 1000);

    const easeInOutCubic = t => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

    const startTime = performance.now();
    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const t = Math.min(elapsedTime / (numFrames * frameDuration), 1);
      const easedT = easeInOutCubic(t);

      const lat = start.lat + (end.lat - start.lat) * easedT;
      const lng = start.lng + (end.lng - start.lng) * easedT;
      const altitude = 1.5 - Math.sin(Math.PI * easedT) * 0.5;

      globeEl.current.pointOfView({ lat, lng, altitude }, 0);

      if (t < 1) {
        requestAnimationFrame(animate);
      } else {
        globeEl.current.pointOfView({ lat: end.lat, lng: end.lng, altitude: 1.8 }, 1000);
        setTimeout(async () => {
          try {
            recorder.current.stopRecording(async () => {
              console.log('Recorder stopped, processing video...');
              const blob = recorder.current.getBlob();
              console.log('Blob created:', blob);

              const mp4Url = await processVideo(blob);

              setVideoUrl(mp4Url);
              setIsGenerating(false);
              setIsRecording(false);

              clearTimeout(generationTimeoutRef.current);
              clearInterval(processingTimerRef.current);
              clearInterval(countdownTimerRef.current);
              clearInterval(elapsedTimeRef.current);
              setProcessingTime(0);
              setRemainingTime(0);
              setElapsedTime(0);

              console.log('Video processing completed successfully');
            });
          } catch (error) {
            console.error('Error during video processing:', error);
            cancelGeneration();
          }
        }, 1000);
      }
    };

    requestAnimationFrame(animate);
  };

  useEffect(() => {
    return () => {
      cancelGeneration();
    };
  }, [cancelGeneration]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Animate Flight Path
            </Typography>
            <Button color="inherit" onClick={() => setShowAbout(!showAbout)}>
              {showAbout ? 'Hide About' : 'About'}
            </Button>
          </Toolbar>
        </AppBar>
        {showAbout ? (
          <About />
        ) : (
          <Box sx={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
            <Paper
              elevation={3}
              sx={{
                width: 300,
                p: 2,
                overflowY: 'auto',
                zIndex: 10,
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                left: 0,
                top: 40,
                height: '50%',
              }}
            >
              <Typography variant="h5" component="h2" gutterBottom>
                Flight Path Recorder
              </Typography>
              {['Departure', 'Arrival'].map((type, index) => (
                <Autocomplete
                  key={type}
                  options={airports}
                  getOptionLabel={(option) => option.name || ''}
                  renderInput={(params) => <TextField {...params} label={`${type} Airport`} margin="normal" />}
                  value={selectedAirports[index]}
                  onChange={(event, newValue) => handleAirportChange(newValue, index)}
                  sx={{ mb: 2 }}
                />
              ))}
              <Button
                variant="contained"
                color="primary"
                onClick={isGenerating ? cancelGeneration : animateFlightPath}
                disabled={!selectedAirports[0] || !selectedAirports[1]}
                startIcon={<Flight />}
                fullWidth
                sx={{ mb: 2 }}
              >
                {isGenerating 
                  ? `Processing... (${elapsedTime}s elapsed)`
                  : `Create Video`}
              </Button>
              {isGenerating && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={cancelGeneration}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  Cancel Generation
                </Button>
              )}
              {videoUrl && (
                <Button
                  variant="contained"
                  color="secondary"
                  href={videoUrl}
                  download="flight-animation.mp4"
                  fullWidth
                  onClick={() => console.log('Download button clicked, video URL:', videoUrl)}
                >
                  Download MP4 Video
                </Button>
                

                
              )}
            </Paper>
            <Box sx={{ flexGrow: 1, position: 'relative' }}>
              <Globe
                ref={globeEl}
                globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
                bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
                backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
                arcsData={arcData}
                arcColor={'color'}
                arcDashLength={0.2}
                arcDashGap={0.2}
                arcDashAnimateTime={1500}
                arcsTransitionDuration={0}
                arcAltitudeAutoScale={0.1}
                arcStroke={1}
                arcCurveResolution={64}
                arcCircularResolution={64}
                arcDashInitialGap={() => 1}
                arcDashLength={() => arcProgress}
                labelsData={labels}
                labelLat={d => d.lat}
                labelLng={d => d.lng}
                labelText={d => d.text}
                labelSize={1.5}
                labelColor={() => 'white'}
                labelAltitude={0.01}
                labelResolution={3}
              
              />
            </Box>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default App;

