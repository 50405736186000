import React from 'react';
import { Button } from '@mui/material';

const BuyMeCoffeeButton = () => {
  const handleClick = () => {
    window.open('https://www.buymeacoffee.com/theflightguy', '_blank');
  };

  return (
    <Button
      variant="contained"
      onClick={handleClick}
      sx={{
        backgroundColor: '#FFDD00',
        color: '#000000',
        // fontFamily: 'Cookie, cursive',
        // '&:hover': {
        //   backgroundColor: '#E5C700',
        // },
      }}
    >
      Buy me a coffee
    </Button>
  );
};

export default BuyMeCoffeeButton;