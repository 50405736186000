import React from 'react';
import { Typography, Paper, Box } from '@mui/material';
import BuyMeCoffeeButton from './BuyMeCoffeeButton';


function About() {
  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        About Animate Flight Path
      </Typography>
      <Typography variant="body1" paragraph>
      Animate Flight Path is an interactive web application that allows users to visualize and record flight paths between airports around the globe.
      </Typography>
      <Typography variant="body1" paragraph>
        Features:
      </Typography>
      <Box component="ul" sx={{ pl: 4 }}>
        <Typography component="li">Select departure and arrival airports</Typography>
        <Typography component="li">Visualize flight paths on a 3D globe</Typography>
        <Typography component="li">Create and download video animations of flight paths</Typography>
        <Typography component="li">High-quality video(1080p) output in MP4</Typography>
        <Typography component="li"><BuyMeCoffeeButton/></Typography>
      </Box>

      <Typography>Email: flightssorted@gmail.com</Typography>


    </Paper>
  );
}

export default About;